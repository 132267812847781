// AlertPopup.js
import React from 'react';

const AlertPopup = ({ message, onClose }) => {
  return (
    <div className="popupOverlay">
    <div className="popupContent">
        <p>{message}</p>
        <button onClick={onClose}>Okay</button>
      </div>
    </div>
  );
};

export default AlertPopup;
