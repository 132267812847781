import React, { useState } from 'react';
import './Communication.css';

function WhatsApp() {
  const [verificationType, setVerificationType] = useState('');
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState('');

  const [knowName, setKnowName] = useState('');
  const [name, setName] = useState('');
  const [knowCompany, setKnowCompany] = useState('');
  const [company, setCompany] = useState('');
  const [senderPhoneNumber, setSenderPhoneNumber] = useState('');
  const [senderId, setSenderId] = useState('');


  
  const [profilePicture, setProfilePicture] = useState(null);
  const [errors, setErrors] = useState({});

  const handleVerificationTypeChange = (event) => {
    setVerificationType(event.target.value);
    clearError('verificationType');
  };

  const handleKnownNameChange = (event) => {
    setKnowName(event.target.value);
    clearError('knowName');
  };

  const handleKnowCompanyChange = (event) => {
    setKnowCompany(event.target.value);
    clearError('knowCompany');
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
    clearError('name');
  };

  const handleCompanyChange = (event) => {
    setCompany(event.target.value);
    clearError('company');
  };

  const handleProfilePictureChange = (event) => {
    const file = event.target.files[0];
    setProfilePicture(file);
    clearError('profilePicture');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formErrors = {};
    if (!verificationType) formErrors.verificationType = 'Verification type is required';
    if (!conversation) formErrors.conversation = 'Please enter the conversation';

    if (!message) formErrors.message = 'Message is required';
    if (!knowName) formErrors.knowName = 'Knowledge of name is required';
    if (knowName === 'yes' && !name) formErrors.name = 'Name is required';
    if (!knowCompany) formErrors.knowCompany = 'Knowledge of company is required';
    if (knowCompany === 'yes' && !company) formErrors.company = 'Company name is required';
    if (!profilePicture) formErrors.profilePicture = 'Profile picture is required';

    if (Object.keys(formErrors).length === 0) {
      // Handle form submission here, e.g., send data to backend or perform validation
      console.log('Form submitted:', {
        verificationType,
        conversation,
        message,
        knowName,
        name,
        knowCompany,
        company,
        profilePicture,
      });
    } else {
      setErrors(formErrors);
    }
  };

  const clearError = (field) => {
    if (errors[field]) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[field];
        return updatedErrors;
      });
    }
  };

  return (
    <div className="verification-form">
      <h1 className="mb-4">Verifying SMS/Text Message</h1>
      <form onSubmit={handleSubmit}>
      <label>
          Source of SMS/Text:
          <select value={verificationType} onChange={handleVerificationTypeChange}>
            <option value="">Select</option>
            <option value="phone">Phone number</option>
            <option value="code">Sender ID ( VM-HDFC etc.)</option>
          </select>
          {errors.verificationType && <span className="error">{errors.verificationType}</span>}
        </label>
        {verificationType === 'phone' && (
          <div>
            <label>
            Enter Phone number of the Sender:
              <input type="text" value={senderPhoneNumber} onChange={(e) => setSenderPhoneNumber(e.target.value)} 
                            onFocus={() => clearError('senderPhoneNumber')}
              />
              {errors.senderPhoneNumber && <span className="error">{errors.senderPhoneNumber}</span>}
            </label>
          </div>
        )}
        {verificationType === 'code' && (
          <div>
            <label>
            Enter Sender ID ( VM-HDFC etc.):
              <input type="text" value={senderId} onChange={(e) => setSenderId(e.target.value)} />
              {errors.senderId && <span className="error">{errors.senderId}</span>}
            </label>
          </div>
        )}
     
        <div>
            <label>
              Paste the SMS/Text message below:
              <textarea value={conversation} onChange={(e) => setConversation(e.target.value)} 
            onFocus={() => clearError('conversation')}/>
              {errors.conversation && <span className="error">{errors.conversation}</span>}
            </label>
          </div>

   
        <label>
          Do you know the Sender?
          <select value={knowName}  onChange={handleKnownNameChange}>
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {errors.knowName && <span className="error">{errors.knowName}</span>}
        </label>
        {knowName === 'yes' && (
          <div>
            <label>
              Enter the name of the Sender:
              <input type="text" value={name} onChange={handleNameChange} />
              {errors.name && <span className="error">{errors.name}</span>}
            </label>
          </div>
        )}
        <label>
          Do you know the name of the company where Sender works?
          <select value={knowCompany}  onChange={handleKnowCompanyChange}>
            <option value="">Select</option>
            <option value="yes">Yes</option>
            <option value="no">No</option>
          </select>
          {errors.knowCompany && <span className="error">{errors.knowCompany}</span>}
        </label>
        {knowCompany === 'yes' && (
          <div>
            <label>
              Enter the name of the company:
              <input type="text" value={company} onChange={handleCompanyChange} />
              {errors.company && <span className="error">{errors.company}</span>}
            </label>
          </div>
        )}
       
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default WhatsApp;
