// EducationalResources.js
import React from 'react';

const EducationalResources = () => {
  return (
    <div className="container mt-4">
      <h1 className="mb-4">Educational Resources</h1>
      <p>Learn about common scams and how to protect yourself online.</p>
      <p>Resource 1</p>
      <p>Resource 2</p>
      <p>Resource 3</p>
    </div>
  );
};

export default EducationalResources;
