import React from 'react';

class ClickableFloorLayout extends React.Component {
  state = {
    imageWidth: 0,
    imageHeight: 0
  };

  handleImageLoad = (event) => {
    const { naturalWidth, naturalHeight } = event.target;
    this.setState({ imageWidth: naturalWidth, imageHeight: naturalHeight });
  };

  handleClick = (event) => {
    const { left, top } = event.target.getBoundingClientRect();
    const { clientX, clientY } = event;
    const { imageWidth, imageHeight } = this.state;
    const x = clientX ;
    const y = clientY ;
    const xRelative = (x / (imageWidth+x)); // Adjusted to have 1.0 at the right edge
    const yRelative = y / (imageHeight+top);
    alert(`Clicked at (${x}, ${y}) : ${left}, ${top} : ${xRelative}, ${yRelative} relative to the image`);
};


  render() {
    return (
      <div style={{ position: 'relative', width: '1000px', height: '1000px',margin:'0px'}}>
        <img
          src="site_layout.jpeg"
          alt="Floor Layout Image"
          style={{ width: '100%', height: '100%' }}
          onLoad={this.handleImageLoad}
          onClick={this.handleClick}
          margin={0}
        />
      </div>
    );
  }
}

export default ClickableFloorLayout;
