import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


const SuspectDetailsForm = () => {

  const [error, setError] = useState('');
  const [errorOrg, setErrorOrg] = useState('');
  const [errorOrgName, setErrorOrgName] = useState('');



  

  const [formData, setFormData] = useState({
    knowsSuspect: '',
    fromOrganisation: '',
    relationshipWithOrganisation: '',
    weblink: false,
    email: false,
    phone: false,
    socialMedia: false,
    weblinkInput: '',
    emailInput: '',
    phoneInput: '',
    socialMediaInput: ''
  });
  const location = useLocation();
  const {verifyData } = location.state;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });

    setError('');
    setErrorOrg('');
  };

  const handleNext = () => {
    // Handle next logic, you can navigate or perform any other action here


  // Check if one of the radio buttons is checked
  if (formData.knowsSuspect === '') {
     setError('Please select whether you know the suspect or not.'); // Display error message
    return; // Prevent further execution of the function
  }

  if (formData.fromOrganisation === '') {
    setErrorOrg('Please select whether suspect is from any Organisation or not'); // Display error message
   return; // Prevent further execution of the function
  }

  if (formData.relationshipWithOrganisation === 'yes'){

  }

  

    const updatedVerifyData = {
      ...verifyData, // Copy existing values

      familiarity :{
      knowsthesuspect: formData.knowsSuspect ,// Add new key-value pair
      knowssuspectorg: formData.fromOrganisation, // Add new key-value pair
      suspectorgname: formData.suspectOrgName, // Add new key-value pair
      userorgrelationship: formData.relationshipWithOrganisation, // Add new key-value pair
      },
      suspectcontactdetails:{
        suspect_email : formData.emailInput,
        suspect_phone : formData.phoneInput,
        suspect_weblink :formData.weblinkInput,
        suspect_social :{
          social_name : formData.socialMediaName,
          social_handle :formData.socialMediaInput
        }
      }

  };

   // Handle next logic, you can navigate or perform any other action here
   navigate('/verify-page_v4', {
    state: {
        verifyData: updatedVerifyData // Pass updated verifyData to the next page
    }
});

  };

  const handleBack = () => {
    // Navigate back to the previous screen
    window.history.back();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Form data:', formData);
    // Add form submission logic here
  };

  return (
    <div>
      <h2>How well do you know them?</h2>
      <form onSubmit={handleSubmit}>
        <fieldset>
          <label>Do you know the Person (Suspect)?</label>
          <div>
            <input className='radio-input'
              type="radio"
              name="knowsSuspect"
              value="yes"
              checked={formData.knowsSuspect === 'yes'}
              onChange={handleChange}
            />
            <label className='radio-label'>Yes</label>
        
            <input className='radio-input'
              type="radio"
              name="knowsSuspect"
              value="no"
              checked={formData.knowsSuspect === 'no'}
              onChange={handleChange}
            />
            <label className='radio-label'>No</label>
          </div>
        </fieldset>
        {error && <div style={{ color: 'red' }}>{error}</div>}

        <fieldset>
          <label>Are they from any organisation?</label>
          <div>
            <input className='radio-input'
              type="radio"
              name="fromOrganisation"
              value="yes"
              checked={formData.fromOrganisation === 'yes'}
              onChange={handleChange}
            />
            <label className='radio-label'>Yes</label>
        
            <input className='radio-input'
              type="radio"
              name="fromOrganisation"
              value="no"
              checked={formData.fromOrganisation === 'no'}
              onChange={handleChange}
            />
           <label className='radio-label'>No</label>
          </div>
        </fieldset>
        {errorOrg && <div style={{ color: 'red' }}>{errorOrg}</div>}

        {formData.fromOrganisation === 'yes' && (
          <>
        <fieldset>
          <label>Enter the name of Organisation of the Suspect</label>
          <div>
            <input 
              type="text"
              name="suspectOrgName"
              value={formData.suspectOrgName }
              onChange={handleChange}
              required
            />
          </div>
        </fieldset>
        {errorOrgName && <div style={{ color: 'red' }}>{errorOrgName}</div>}

        <fieldset>
          <label>Do you have a relationship with that organisation?</label>
          <div>
            <input className='radio-input'
              type="radio"
              name="relationshipWithOrganisation"
              value="yes"
              checked={formData.relationshipWithOrganisation === 'yes'}
              onChange={handleChange}
              required
            />
           <label className='radio-label'>Yes</label>
       
            <input className='radio-input'
              type="radio"
              name="relationshipWithOrganisation"
              value="no"
              checked={formData.relationshipWithOrganisation === 'no'}
              onChange={handleChange}
            />
            <label className='radio-label'>No</label>
          </div>
        </fieldset>
        </>
        )}
        <fieldset>
          <label>Do they share any of the following details?</label>
          <div>
            <input className='radio-input'
              type="checkbox"
              name="weblink"
              checked={formData.weblink}
              onChange={handleChange}
            />
            <label className='radio-label'>Weblink/Website</label>
            {formData.weblink && (
              <input 
                type="text"
                name="weblinkInput"
                value={formData.weblinkInput}
                onChange={handleChange}
                placeholder="Enter weblink/website"
              />
            )}
          </div>

          <div>
            <input
              type="checkbox" className='radio-input'
              name="email"
              checked={formData.email}
              onChange={handleChange}
            />
            <label className='radio-label'>Email id</label>
            {formData.email && (
              <input
                type="text"
                name="emailInput"
                value={formData.emailInput}
                onChange={handleChange}
                placeholder="Enter email id"
              />
            )}
          </div>

          <div>
            <input className='radio-input'
              type="checkbox"
              name="phone"
              checked={formData.phone}
              onChange={handleChange}
            />
            <label className='radio-label'>Phone number</label>
            {formData.phone && (
              <input 
                type="text"
                name="phoneInput"
                value={formData.phoneInput}
                onChange={handleChange}
                placeholder="Enter phone number"
              />
            )}
          </div>

          <div>
            <input className='radio-input'
              type="checkbox"
              name="socialMedia"
              checked={formData.socialMedia}
              onChange={handleChange}
            />
            <label className='radio-label'>Social Media Handle</label>
            {formData.socialMedia && (
              <>
              <input
                type="text"
                name="socialMediaName"
                value={formData.socialMediaName}
                onChange={handleChange}
                placeholder="Enter social media Name"
              />
                <input
                type="text"
                name="socialMediaInput"
                value={formData.socialMediaInput}
                onChange={handleChange}
                placeholder="Enter social media handle"
              />
              </>
            )}
          </div>
        </fieldset>
        <button onClick={handleBack} className='back_button'>Back</button>
      <button onClick={handleNext} className='next_button'>Next</button>
            </form>
    </div>
  );
};

export default SuspectDetailsForm;
