import React from 'react';

import './JoinTheWaitlist.css';
import { useNavigate } from 'react-router-dom';


function JoinTheWaitlist() {

    const navigate = useNavigate();


  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const formData = new FormData(event.target);
    const data = {
      name: formData.get('name'),
      email: formData.get('email'),
      phone: formData.get('phone'),
      message: formData.get('message')
    };

    console.log("VERIFYPE_BACKEND:", process.env.REACT_APP_VERIFYPE_BACKEND);

    try {  
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/jointhewaitlist`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        alert('Thank you for joining the waitlist!');
        navigate('/');

      } else {
        throw new Error('Failed to join the waitlist');
      }
    } catch (error) {
      console.error('Error joining the waitlist:', error.message);
      alert('Failed to join the waitlist. Please try again later.');
    }
  };

  return (
    <div className="form-container">
      <h1>Welcome to the Waitlist</h1>
      <p>Thank you for your interest! Please fill out the form below to join the waitlist.</p>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input type="text" name="name" required />
        </label>
        <br />
        <label>
          Email:
          <input type="email" name="email" required />
        </label>
        <br />
        <label>
          Phone Number:
          <input type="tel" name="phone" pattern="[0-9+]+" required />
          {/* <small>Format: Coutry/small> */}
        </label>
        <br />
        <label>
          Message:
          <textarea name="message" rows="3" cols="50"></textarea>
        </label>
        <br />
        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default JoinTheWaitlist;
