import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const StatusUpdatePage = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const requestIdFromURL = urlParams.get('requestid');
  const [statusData, setStatusData] = useState(null);
  const [searchRequestId, setSearchRequestId] = useState('');
  const [verfiiedCaseStatus, setVerifiedCaseStatus] = useState('');
  const [casedetails, setCasedetails] = useState(null);

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (requestIdFromURL) {
      fetchStatus(requestIdFromURL);
    }
  }, [requestIdFromURL]);

  const fetchStatus = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/get-verified-details?requestid=${requestId}`);
      const data = await response.json();
      console.log("verified_details : ",data.verified_details);

      console.log("casedetails :",data.verified_details.casedetails);


      
      const jsonString = data.verified_details.casedetails;

        // Replace single quotes with double quotes
        const validJsonString = jsonString.replace(/'/g, '"');

        try {
        // Convert JSON string to JSON object
        const jsonObject = JSON.parse(validJsonString);
        setCasedetails(jsonObject)
        console.log('parsing : ' , casedetails); // Output: { channel: 'whatsAPP' }
        } catch (error) {
        console.error('Error parsing JSON:', error);
        }

   
        if (!data.verified_details.verifiedcasestatus){
        setVerifiedCaseStatus('Pending');
      }
      setStatusData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = async () => {
    if (!searchRequestId) return;
    fetchStatus(searchRequestId);
  };

  return (
    <div>
          <h2>Status Update</h2>
       <div>
        <input
          type="text"
          placeholder="Enter Request ID"
          //value="SN9TH47WUO3UMYKS"
          value={searchRequestId}
          onChange={(e) => setSearchRequestId(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

        <br></br>

      {statusData ? (
        <>
        <div className='summary-container'>
            
        <div className={`first-half-summary ${expanded ? 'expanded' : ''}`}>
        
        <strong><label> Verification Status for {casedetails.channel.heading}</label></strong>

            <p className='disclaimer_class'>Verfication Request #: {statusData.verified_details.casenumber}</p>
            <p className='disclaimer_class'>Created on : {statusData.verified_details.created_date}</p>
            
            <br></br>
            {casedetails.channelcontacts.whatsappNumberVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.whatsappNumberVerify}</p>
            )}
            {casedetails.channelcontacts.smsNumberVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.smsNumberVerify}</p>
            )}

            {casedetails.channelcontacts.emailVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.emailVerify}</p>
            )}

            {casedetails.channelcontacts.callVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.callVerify}</p>
            )}


            {casedetails.channelcontacts.weblinkVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.weblinkVerify}</p>
            )}


            {casedetails.channelcontacts.weblinkVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.weblinkVerify}</p>
            )}

            {casedetails.channelcontacts.purposeToVerify && (
                <p className='label-summary'><strong>Verfication Purpose: </strong>{casedetails.channelcontacts.purposeToVerify}</p>

            )}

            <p className='label-summary'><strong>Your Case: </strong><br></br>{casedetails.suspectedconversation.describedmessage}</p>
            <p className='label-summary'><strong>Suspect's Message: </strong><br></br>{casedetails.suspectedconversation.originalmessage}</p>
        
            
            <p className='label-summary'><strong>Suspect's Details: </strong></p>    
            <p className='label-summary'>Web-link: {casedetails.suspectcontactdetails.suspect_weblink}</p>
            <p className='label-summary'>Phone number: {casedetails.suspectcontactdetails.suspect_phone}</p>
            <p className='label-summary'>Email id: {casedetails.suspectcontactdetails.suspect_email}</p>
            <p className='label-summary'>Social Media: {casedetails.suspectcontactdetails.suspect_social.social_name} {casedetails.suspectcontactdetails.suspect_social.social_handle}</p>
    
            <p className='label-summary'><strong>Supporting Proofs: </strong></p>
                {casedetails.attachments.map((attachment, index) => (
                    <p key={index} className='label-summary'>
                    Description: {attachment.description} 
                    <img src={attachment.file} width="30px" alt="Attachment" />
                    </p>
                ))}
            </div>
            <a href="#" className="more-link" onClick={toggleExpanded}>
        {expanded ? 'Less' : 'More'}
        </a>

            <br></br>

            <div className='second-half-summary'>
            <strong><label>Verfication Verdict</label></strong>
            <p className='disclaimer_class' >Verification Status: {verfiiedCaseStatus}</p>

            </div>
           
            </div>
        </>

      ) : (
        <p>No status available</p>
      )}
   
    </div>
  );
};

export default StatusUpdatePage;
