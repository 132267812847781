import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function Page1() {
  const [whatsappNumber, setWhatsappNumber] = useState('');
  const [smsNumber, setSMSNumber] = useState('');
  const [emailVerify, setEmailVerify] = useState('');
  const [callVerify, setCallVerify] = useState('');
  const [weblinkVerify, setWeblinkVerify] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { key, heading } = location.state;
  const [purpose, setPurpose] = useState(heading);
  const [otherPurpose, setOtherPurpose] = useState('');

  const handlePurposeChange = (e) => {
    setPurpose(e.target.value);

  };

  const handleOtherPurposeChange = (e) => {
    setOtherPurpose(e.target.value);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('whatsappNumber Number to Verify:', whatsappNumber);
    console.log('Purpose of Conversation:', purpose);

    // Pass form data to the next page as key-value pairs
    navigate('/verify-page_v2', {
      state: {
        verifyData: {
          channel : {
            key:key,
            heading:heading
          },
          channelcontacts :{
            whatsappNumberVerify: whatsappNumber,
            smsNumberVerify: smsNumber,
            emailVerify: emailVerify,
            callVerify: callVerify,
            weblinkVerify: weblinkVerify,
            purposeToVerify: purpose
          }
        }
      }
    });
  };

  return (
    
    <form onSubmit={handleSubmit}>
          <h2>{heading} Verification</h2>

        { (key==='whatsapp' &&
        <>
            <label>Enter {heading} number you want to verify:</label>
            <input
              type="text"
              id="whatsappNumber"
              placeholder="Please include Country Code"
              value={whatsappNumber}
              required
              onChange={(e) => setWhatsappNumber(e.target.value)}
            />
        </>
          )
        }
        { (key==='sms' &&
        <>
            <label>Enter {heading} Sender Number/ID you want to verify:</label>
            <p> Example : Sender IDs (VM-HDFCBK , TM-FACEBOOK etc.) </p>
            <input
              type="text"
              id="smsNumber"
              value={smsNumber}
              onChange={(e) => setSMSNumber(e.target.value)}
              required
            />
        </>
          )
        }

        { (key==='email' &&
        <>
            <label>Enter {heading} ID you want to verify:</label>
            <input
              type="email"
              id="emailVerify"
              value={emailVerify}
              onChange={(e) => setEmailVerify(e.target.value)}
              required
            />
        </>
          )
        }

        { (key==='call' &&
        <>
            <label>Enter Phone Number you want to verify:</label>
            <input
              type="text"
              id="callVerify"
              value={callVerify}
              onChange={(e) => setCallVerify(e.target.value)}
              placeholder="Please include Country Code"
              required
            />
        </>
          )
        }

        { (key==='weblink' &&
        <>
            <label>Enter {heading} you want to verify:</label>
            <input
              type="text"
              id="weblinkVerify"
              value={weblinkVerify}
              onChange={(e) => setWeblinkVerify(e.target.value)}
              required
            />
        </>
          )
        }      
      
      <label>Verification is related with: </label>
      <select id="purpose" value={purpose} onChange={handlePurposeChange} required>
        <option value="">Select a purpose</option>
        <option value="Banking">Banking Services</option>
        <option value="Loan">Loan/Credit Card</option>
        <option value="Investment Scheme">Investment Scheme</option>
        <option value="MoneyMaking">Earning Money Quickly</option>
        <option value="Job Offer">Job Offer</option>
        <option value="Dating/Relationship">Relationship/Dating</option>

        <option value="Customs">Customs Department</option>
        <option value="Parcel">Parcel Delivery</option>
        <option value="Blackmail/Criminal Case">Blackmail/Criminal Case Against You</option>
        <option value="Others">Others</option>
        

      </select>


      {purpose === 'Others' && (
        <input
          type="text"
          id="other-purpose"
          value={otherPurpose}
          onChange={handleOtherPurposeChange}
          placeholder="Please specify"
        />
      )}
      <button type="submit" className='next_button'>Next</button>
    </form>
  );
}

export default Page1;