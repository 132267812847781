import React from 'react';
import { useNavigate } from 'react-router-dom';

import './Verify.css'

const channels = [
  { heading: "WhatsApp", key: "whatsapp" },
  { heading: "SMS/Text", key: "sms" },
  { heading: "Email", key: "email" },
  { heading: "Call", key: "call" },
  { heading: "Web-Link", key: "weblink" },
  { heading: "Social Media DMs", key: "social_media" },


  { heading: "Dating/Relationship", key: "dating" },
  { heading: "Job Offer", key: "job" },
  { heading: "Investment Scheme", key: "investment" },
  { heading: "Blackmail/Criminal Case", key: "crime" }
];


function ChannelTiles() {

  const navigate = useNavigate();


  const handleClick = (key, heading) => {

   //alert("Clicked Channel - Key: " + key + ", Heading: " + heading);
    console.log("Clicked Channel - Key:", key, "Heading:", heading);
    
      navigate('/verify-page_v1', { state: { key, heading } });

   

  };

  return (
    <div>
       <div className="mission">
          <h2 className="mission-heading">Verify Anything</h2>
        </div>
    <div className="channel-tiles">
   
      {channels.map(channel => (
        <div className="channel-tile" key={channel.key} onClick={() => handleClick(channel.key, channel.heading)}>
          <img
            src={`/${channel.key}.jpeg`} // Assuming images are named after the key
            alt={channel.heading}
            className="channel-image"
          />
          <p className="channel-heading">{channel.heading}</p>
        </div>
        
      ))}
   
    </div>
    <div className="get-started-link" onClick={() => handleClick('others', 'Choose the cause for ')}>
        <a href="/verify-page_v1">Not Sure? Click here to get started.</a>
      </div>
      </div>
  );
}

export default ChannelTiles;
