import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function VerifyPageV2() {
  const [conversation, setConversation] = useState('');
  const [originalMessage, setOriginalMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const {verifyData } = location.state;
  const [error, setError] = useState('');

  const handleNext = async () => {

    const conversationWordCount = conversation.trim().split(/\s+/).length;

    if (conversation.trim() === '' || conversationWordCount < 10) {
      
      await setError('Conversation cannot be empty or must contain at least 10 words.');


      return;

    } else {
      // Your form submission logic here      
      setError('');
      // Clear the textarea after submission if needed
    }

    
    const updatedVerifyData = {
      ...verifyData, // Copy existing values

      suspectedconversation :{
      describedmessage: conversation ,// Add new key-value pair
      originalmessage: originalMessage // Add new key-value pair
      }

  };

   // Handle next logic, you can navigate or perform any other action here
   navigate('/verify-page_v3', {
    state: {
        verifyData: updatedVerifyData // Pass updated verifyData to the next page
    }
});

  };

  const handleBack = () => {
    // Navigate back to the previous screen
    window.history.back();
  };

  return (
    <div>
      <h2> What was the Conversation?</h2>
      <form>
        <label htmlFor="conversation">Describe the conversation:</label>
        <textarea
          rows={5}
          id='conversation'
          value={conversation}
          onChange={(e) => setConversation(e.target.value)}
          
        />

    {error && <div style={{ color: 'red' }}>{error}</div>}

        <label htmlFor="original-message">Paste the original message received from them:</label>
        <textarea
          rows={5}
          id='original-message'
          value={originalMessage}
          onChange={(e) => setOriginalMessage(e.target.value)}
          onKeyPress={(e) => e.preventDefault()} 
          placeholder='Paste the message. Typing not allowed.'
        />
      </form>
      <button onClick={handleBack} className='back_button'>Back</button>
      <button onClick={handleNext} className='next_button'>Next</button>
    </div>
  );
}

export default VerifyPageV2;
