// Contact.js
import React, { useState } from 'react';
import AlertPopup from './AlertPopup';

const Contact = () => {
  const [showAlert, setShowAlert] = useState(false);

  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent form submission
    handleShowAlert(); // Show the alert
    // Additional form submission logic can be added here
  };
  
  return (
    <div className="container mt-4">
    
      <h1 className="mb-4">Contact Us</h1>
      <p>Have a question or feedback? Send us a message!</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <input type="text" className="form-control" placeholder="Name" required />
        </div>
        <div className="mb-3">
          <input type="email" className="form-control" placeholder="Email" required />
        </div>
        <div className="mb-3">
          <input type="tel" className="form-control" placeholder="Phone Number" required />
        </div>
        <div className="mb-3">
          <textarea className="form-control" placeholder="Message" required />
        </div>
        <button type="submit" className="btn btn-primary">Send</button>
      </form>

      {showAlert && <AlertPopup message="This is an alert message" onClose={handleAlertClose} />}

    </div>
  );
};

export default Contact;
