import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';


  const SupportForm = ({ onClose, verifyData }) => {

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    donation: 2
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSliderChange = (e) => {
    setFormData({
      ...formData,
      donation: e.target.value
    });
  };

  const handleSubmit  = async (e) => {
    e.preventDefault();
    // Here you can handle form submission, e.g., send the data to a server
    console.log('Form Data Submitted:', formData);

    verifyData = {
      ...verifyData, // Copy existing values

      userdetails :{
        username:formData.name,
        useremail:formData.email,
        userphone:formData.phone,
        userdonation:formData.donation
      }

  };
  console.log('verifyData  verifyData ',verifyData);


  console.log('Form Data Submitted:', formData);
  const formDataToSend = { ...verifyData }; // Create a copy of verifyData to avoid mutating original data

  if (formDataToSend.attachments) {
    // Filter out null or undefined attachments before processing
    const validAttachments = formDataToSend.attachments.filter(attachment => attachment && attachment.file);

    // Convert valid File objects to base64 strings before sending
    const attachmentsWithBase64 = await Promise.all(validAttachments.map(async attachment => {
      const base64 = await fileToBase64(attachment.file);
      return {
        description: attachment.description,
        file: base64
      };
    }));

    formDataToSend.attachments = attachmentsWithBase64;
  } else {
    formDataToSend.attachments = [];
  }

  const caseNumber = generateRandomCaseNumber(16); // Generate a random case number

  const requestBody = {
    casenumber: caseNumber,
    casedetails: formDataToSend,
    casestatus: 'open'
  };

  // Send formDataToSend to the server
  const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/generate-case`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(requestBody)
  });


  const responseData = await response.json();

  if (response.ok) {
    // Handle success
    onClose();
    console.log('Request sent successfully!');
    navigate('/success-message', {
      state: {
        caseNumber: caseNumber // Pass updated verifyData to the next page
      }
  });
    
  } else {
    // Handle error
    console.error('Error sending request:', responseData);
    alert(responseData.message);
  }

  };


  // Function to generate a random case number (you can replace this with your actual implementation)
const generateRandomCaseNumber = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

  // Function to convert a File object to a base64 string
const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });};

  return (
    <div className="modal">
      <div className="modal-content">
      <button className="close-button" onClick={onClose}>X</button>
      <h2>Get Status for Verification Request</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Your Name:</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Your Email:</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>
        <div>
          <label>Your Phone Number:</label>
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>
        {/* <div>
          <label>Donation Amount: </label>
          <label><s>US $ {formData.donation}</s></label>
          <input
            type="range"
            name="donation"
            min="1"
            max="200"
            value={formData.donation}
            onChange={handleSliderChange}
          />
        </div> */}
       
        <button type="submit" className='home-button'>Submit</button>
      </form>
    </div>
  </div>

  );
};

export default SupportForm;
