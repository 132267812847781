import React, { useState, useEffect } from 'react';
import { Route, Routes, Link } from 'react-router-dom';
import Home from './Components/Home';
import DetectScam from './Components/DetectScam';
import EducationalResources from './Components/EducationalResources';
import ClickableFloor from './Components/ClickableFloor';
import Contact from './Components/Contact';
import Verify from './Components/Verify';


import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';


import './App.css';
import WhatsApp from './Components/WhatsApp';
import SMS from './Components/SMS';

import Page_v1 from './Components/VerificationSteps/VerifyInitial';
import Page_v2 from './Components/VerificationSteps/VerifyConversation';
import Page_v3 from './Components/VerificationSteps/VerifyFamiliar';
import Page_v4 from './Components/VerificationSteps/VerifyProofs';
import Page_v5 from './Components/VerificationSteps/UserDetails';
import SuccessPage from './Components/VerificationSteps/SuccessPage';


import StatusUpdate from './Components/VerificationSteps/StatusUpdate';

import AdminPanel from './Components/Admin/CaseReviewList';
import AdminVerdict from './Components/Admin/AdminCaseReview';


import WaitlistPage from './Components/JoinTheWaitlist';



const App = () => {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(60);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true); // State to manage collapse

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed); // Function to toggle collapse


  useEffect(() => {
    const timer = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      } else {
        if (minutes === 0) {
          clearInterval(timer);
          // Timer has reached 0 minutes and 0 seconds
          // You can perform any action here when the timer finishes
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup the interval on component unmount
  }, [seconds, minutes]);

  // Format the minutes and seconds to display with leading zeros
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return (
    <div>
       <nav className="navbar navbar-expand-lg navbar-light bg-white">
       <div className="container">
       <Link className="navbar-brand" to="/" onClick={() => setIsNavCollapsed(true)}>
       <img src="logo.png" alt="favicon" className="favicon" />
       VerifyPe</Link>
          {/* <button 
            className="navbar-toggler" 
            type="button" 
            data-bs-toggle="collapse" 
            data-bs-target="#navbarNav" 
            aria-controls="navbarNav" 
            aria-expanded={!isNavCollapsed ? true : false} 
            aria-label="Toggle navigation"
            onClick={handleNavCollapse} 
          >
            <span className="navbar-toggler-icon"></span>
          </button> */}
          <div className={`${isNavCollapsed ? 'collapse' : ''} navbar-collapse`} id="navbarNav">
          
            <ul className="navbar-nav ms-auto">
              {/* <li className="nav-item" onClick={() => setIsNavCollapsed(true)}> 
                <Link className="nav-link" to="/detect-scam">Verify</Link>
              </li>
              <li className="nav-item" onClick={() => setIsNavCollapsed(true)}> 
                <Link className="nav-link" to="/click-image">Report</Link>
              </li>
              <li className="nav-item" onClick={() => setIsNavCollapsed(true)}> 
                <Link className="nav-link" to="/educational-resources">Current Trends</Link>
              </li>*/}
              {/* <li className="nav-item" onClick={() => setIsNavCollapsed(true)}> 
                <Link className="nav-link" to="/contact">Report</Link>
              </li>  */}
              <li className="nav-item" onClick={() => setIsNavCollapsed(true)}> 
                <Link className="nav-link" to="/get-verified-details">Check Status</Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <div className="container mt-4">
        {/* <div className="timer-container">
          <h1>Countdown Timer</h1>
          <div>
            Time Remaining: {formattedMinutes}:{formattedSeconds}
          </div>
        </div> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/detect-scam" element={<DetectScam />} />
          <Route path="/whatsapp-verify" element={<WhatsApp />} />
          <Route path="/sms-verify" element={<SMS />} />


          <Route path="/verify-page_v1" element={<Page_v1 />} />
          <Route path="/verify-page_v2" element={<Page_v2 />} />
          <Route path="/verify-page_v3" element={<Page_v3 />} />
          <Route path="/verify-page_v4" element={<Page_v4 />} />
          <Route path="/verify-page_v5" element={<Page_v5 />} />
          <Route path="/success-message" element={<SuccessPage />} />

          <Route path="/admin-panel" element={<AdminPanel />} />
          <Route path="/admin-case-verdict" element={<AdminVerdict />} />



          

          <Route path="/get-verified-details" element={<StatusUpdate />} />

          <Route path="/verify" element={<Verify />} />
          <Route path="/join-the-waitlist" element={<WaitlistPage />} />


          <Route path="/click-image" element={<ClickableFloor />} />
          <Route path="/educational-resources" element={<EducationalResources />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
