import React from 'react';

import { useLocation, useNavigate } from 'react-router-dom';


function SuccessPage() {

  const navigate = useNavigate();
  const location = useLocation();


  const {caseNumber } = location.state;


  const handleOkay = () => {

    navigate('/');

  }

  return (
    <div>

     <div>
      <h2>Your request is submitted successfully.</h2>
      <br></br>
      <label>Please note the reference number {caseNumber} for checking the updates.</label>
      </div>
      <br></br>
      <div>
          <p className='disclaimer_class'>VerifyPe takes approximately 60 minutes to complete the analysis.</p>
          <p className='disclaimer_class'>We will send an email to your email address with the status of your request.</p>
          <p className='disclaimer_class'>You can also check updates on your request using the request number.</p>
        </div>

      <button onClick={handleOkay} className='next_button'>Okay</button>
    </div>
  );
}

export default SuccessPage;
