import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Modal from './UserDetails';



const FileUploadForm = () => {

  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState('');
  const [currentFile, setCurrentFile] = useState(null);
  const [currentDescription, setCurrentDescription] = useState('');

  const [showModal, setShowModal] = useState(false);

  const location = useLocation();
  const {verifyData } = location.state;


  const navigate = useNavigate();

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    setCurrentFile(e.target.files[0]);

    if (file) {
      setFileName(file.name);
    } else {
      setFileName('No file Uploaded.');
    }
    
  };

  

  const handleDescriptionChange = (e) => {
    setCurrentDescription(e.target.value);
  };

  const handleUpload = () => {
    if (currentFile && currentDescription.trim() !== '') {
      const newAttachment = { file: currentFile, description: currentDescription };
      setFiles((prevFiles) => [...prevFiles, newAttachment]);
      
      // Initialize attachments as an empty array if not defined
      const updatedAttachments = verifyData.attachments ? [...verifyData.attachments, newAttachment] : [newAttachment];
      
      // Update verifyData with the new attachments
      const updatedVerifyData = {
        ...verifyData,
        attachments: updatedAttachments
      };
      setCurrentFile(null);
      setCurrentDescription('');
      setFileName('');
      
      // Update the verifyData state
      location.state.verifyData = updatedVerifyData;
    }
  };
  

  const handleNext = (e) => {
    e.preventDefault();
    console.log('Selected files:', files);
    
    // navigate('/verify-page_v5'); 
    setShowModal(true);
    
    // Add form submission logic here
  };

  const handleBack = () => {
    // Navigate back to the previous screen
    window.history.back();
  };


  return (
    <div>
      <h2>Attach proofs for Investigation</h2>
      <p> Please take screenshot of profile pictures, social media pictures , emails or any communicated you had with the suspect</p>

      <form onSubmit={handleNext}>
        <div>
        <label>(Only images are allowed)</label>
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            onChange={handleFileChange}
          />
           <label htmlFor="fileInput" className="custom-file-upload">
        {fileName}
      </label>


          <input
            type="text"
            placeholder="Enter description"
            value={currentDescription}
            onChange={handleDescriptionChange}
          />
          <button type="button" onClick={handleUpload}>
            Upload File
          </button>
        </div>

        <div>
        <br></br>
          {/* <h3>Selected Files:</h3> */}
          <ul>
            {files.map((fileObj, index) => (
              <li key={index}>
                {fileObj.file.name} - {fileObj.description}
              </li>
            ))}
          </ul>
        </div>

        <button onClick={handleBack} className='back_button'>Back</button>
      <button onClick={handleNext} className='next_button'>Done</button>
      </form>
      {showModal && (
        <Modal verifyData={verifyData}  onClose={() => setShowModal(false)}>
        </Modal>
      )}
    </div>
  );
};

export default FileUploadForm;
