import React, { useState } from 'react';

const DetectScam = () => {
  const [step, setStep] = useState(1);
  const [message, setMessage] = useState('');
  const [hasMessage, setHasMessage] = useState('');
  const [noMessageReason, setNoMessageReason] = useState('');
  const [relationship, setRelationship] = useState('');
  const [moneyExchange, setMoneyExchange] = useState('');
  const [threatsIntimidation, setThreatsIntimidation] = useState('');
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userPhone, setUserPhone] = useState('');
  
  const [contact, setContact] = useState('');
  const [proof, setProof] = useState('');

  const [photo, setPhoto] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);


  const [moneyMakingScheme, setMoneyMakingScheme] = useState(false);
  const [moneyRequested, setMoneyRequested] = useState(false);
  const [howDoYouKnow, setHowDoYouKnow] =useState(false);


  // Function to handle changes in the "Money-Making Scheme" checkbox
  const handleMoneyMakingSchemeChange = (event) => {
      setMoneyMakingScheme(event.target.checked);
  };

  // Function to handle changes in the "Money Requested" checkbox
  const handleMoneyRequestedChange = (event) => {
      setMoneyRequested(event.target.checked);
  };

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleSubmit = async (event) => {
    alert("inside alert of submit")
    event.preventDefault();
    setIsLoading(true);

    const formData = {
      message,
      contact,
      photo,
    };

    // Create a promise that resolves after 10 seconds
    const timeoutPromise = new Promise((resolve) => {
      setTimeout(() => {
        resolve({ success: false, message: 'Timeout: No response from server.' });
      }, 10000); // 10 seconds
    });

    try {
      // Make the API call
      // const apiCallPromise = axios.post('/api/detect-scam', formData);

      // Race between the API call promise and the timeout promise
      // const response = await Promise.race([apiCallPromise, timeoutPromise]);

      // If the API call resolves first, update the result
      // setResult(response);
    } catch (error) {
      console.error('Error detecting scam:', error);
      setResult({ success: false, message: 'An error occurred while detecting scam.' });
    } finally {
      setIsLoading(false);
    }
  };
  const [contactMethods, setContactMethods] = useState({
    phoneNumber: false,
    email: false,
    whatsapp: false,
    socialMedia: false
});

const [phoneNumber, setPhoneNumber] = useState('');
const [sms, setSMS] = useState('');

const [whatsapp, setWhatsapp] = useState('');

const [email, setEmail] = useState('');
const [socialMedia, setSocialMedia] = useState('');
const [socialMediaID, setSocialMediaID] = useState('');


  const handleCheckboxChange = (method) => {
    setContactMethods({
        ...contactMethods,
        [method]: !contactMethods[method]
    });
};

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className='form-step'>
            <div className="mb-3">
              <label htmlFor="name" >Your Name:</label>
              <input 
                type="text" 
                className="form-control" 
                id="name" 
                name="name" 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" >Your Email:</label>
              <input 
                type="email" 
                className="form-control" 
                id="email" 
                name="email" 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="phone" >Your Phone:</label>
              <input 
                type="tel" 
                className="form-control" 
                id="phone" 
                name="phone" 
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" >Describe your situation:</label>
              <textarea
                id="message"
                className="form-control"
                rows="5"
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                placeholder="Enter your message here..."
                required
              />
            </div>
            <div className="mb-3">
              <button type="button" className="btn btn-primary" onClick={handleNext}>Next</button>
            </div>
          </div>
        );
        
      case 2:
        return (
          <div className="mb-3">
    <div className="mb-3">

    <label htmlFor="relationship" >Is the sender someone you know personally?</label>
        <input 
            type="radio" 
            id="yesPersonal" 
            name="relationship" 
            value="yes" 
            checked={relationship === 'yes'} 
            onChange={() => setRelationship('yes')} 
        />
        
        <label htmlFor="yesPersonal">Yes</label>
        <input 
            type="radio" 
            id="noPersonal" 
            name="relationship" 
            value="no" 
            checked={relationship === 'no'} 
            onChange={() => setRelationship('no')} 
        />
        <label htmlFor="noPersonal">No</label>
        <input 
            type="radio" 
            id="commonFriends" 
            name="relationship" 
            value="commonFriends" 
            checked={relationship === 'commonFriends'} 
            onChange={() => setRelationship('commonFriends')} 
        />
        <label htmlFor="commonFriends">Through common friends</label>
        <input 
            type="radio" 
            id="socialMedia" 
            name="relationship" 
            value="socialMedia" 
            checked={relationship === 'socialMedia'} 
            onChange={() => setRelationship('socialMedia')} 
        />
        <label htmlFor="socialMedia">Through social media</label>
    </div>

<div className="mb-3">
    <label htmlFor="moneyExchange">Does the message involve unexpected exchange of money?</label>
    <div>
        <input 
            type="radio" 
            id="yesMoneyExchange" 
            name="moneyExchange" 
            value="yes" 
            checked={moneyExchange === 'yes'} 
            onChange={() => setMoneyExchange('yes')} 
        />
        <label htmlFor="yesMoneyExchange">Yes</label>
        <input 
            type="radio" 
            id="noMoneyExchange" 
            name="moneyExchange" 
            value="no" 
            checked={moneyExchange === 'no'} 
            onChange={() => setMoneyExchange('no')} 
        />
        <label htmlFor="noMoneyExchange">No</label>
    </div>
</div>

<div className="mb-3">
    <label htmlFor="threatsIntimidation">Are there any threats or intimidation in the message?</label>
    <div>
        <input 
            type="radio" 
            id="yesThreatsIntimidation" 
            name="threatsIntimidation" 
            value="yes" 
            checked={threatsIntimidation === 'yes'} 
            onChange={() => setThreatsIntimidation('yes')} 
        />
        <label htmlFor="yesThreatsIntimidation">Yes</label>
        <input 
            type="radio" 
            id="noThreatsIntimidation" 
            name="threatsIntimidation" 
            value="no" 
            checked={threatsIntimidation === 'no'} 
            onChange={() => setThreatsIntimidation('no')} 
        />
        <label htmlFor="noThreatsIntimidation">No</label>
    </div>
</div>

<div className="mb-3">
              <label htmlFor="knowPerson">How did the person contact you ?</label>
              <p>Please Choose as many as possible.</p>
       
          <div>
              <input type="checkbox" id="sms" onChange={() => handleCheckboxChange('sms')} />
              <label htmlFor="sms">SMS</label>
              {contactMethods.sms && (
                  <input type="text" placeholder= "Person's Phone number/SMS ID" value={sms} onChange={(e) => setSMS(e.target.value)} />
              )}
          </div>
          <div>
              <input type="checkbox" id="whatsapp" onChange={() => handleCheckboxChange('whatsapp')} />
              <label htmlFor="Whatsapp">Whatsapp</label>
              {contactMethods.whatsapp && (
                  <input type="text" placeholder= "Person's Whatsapp number" value={whatsapp} onChange={(e) => setWhatsapp(e.target.value)} />
              )}
          </div>
          <div>
              <input type="checkbox" id="phoneNumber" onChange={() => handleCheckboxChange('phoneNumber')} />
              <label htmlFor="phoneNumber">Call</label>
              {contactMethods.phoneNumber && (
                  <input type="text" placeholder= "Person's Phone number" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
              )}
          </div>
          <div>
              <input type="checkbox" id="email" onChange={() => handleCheckboxChange('email')} />
              <label htmlFor="email">Email</label>
              {contactMethods.email && (
                  <input type="text" placeholder= "Person's Email id"  value={email} onChange={(e) => setEmail(e.target.value)} />
              )}
          </div>
          <div>
              <input type="checkbox" id="socialMedia" onChange={() => handleCheckboxChange('socialMedia')} />
              <label htmlFor="socialMedia">Social Media</label>
              {contactMethods.socialMedia && (
                <>
<input 
    type="text" 
    value={socialMedia} 
    placeholder="Social media Name" 
    onChange={(e) => setSocialMedia(e.target.value)} 
/>

<input 
    type="text" 
    value={socialMediaID} 
    placeholder="Social media handle" 
    onChange={(e) => setSocialMediaID(e.target.value)} 
/>


                </>
              
              )}
          </div>
</div>

<div className="mb-3">
    <label htmlFor="message">Do you have any message from them?</label>
        <input 
            type="radio" 
            id="yesMessage" 
            name="message" 
            value="yes" 
            checked={hasMessage === 'yes'} 
            onChange={() => setHasMessage('yes')} 
        />
        <label htmlFor="yesMessage">Yes</label>
        <input 
            type="radio" 
            id="noMessage" 
            name="message" 
            value="no" 
            checked={hasMessage === 'no'} 
            onChange={() => setHasMessage('no')} 
        />
        <label htmlFor="noMessage">No</label>
    {hasMessage === 'yes' && (
        <div>
            <label htmlFor="messageText">Please Copy and Paste the message , if possible:</label>
            <input 
                type="text" 
                id="messageText" 
                value={message} 
                onChange={(e) => setMessage(e.target.value)} 
            />
        </div>
    )}
    {hasMessage === 'no' && (
        <div>
            <label htmlFor="noMessageText">Describe the message</label>
            <input 
                type="text" 
                id="noMessageText" 
                value={noMessageReason} 
                onChange={(e) => setNoMessageReason(e.target.value)} 
            />
        </div>
    )}
</div>

  <button type="button" className="btn btn-secondary me-2" onClick={() => {setStep(step - 1)}}>Previous</button>
  <button type="button" className="btn btn-primary" onClick={handleNext}>Next</button>

</div>
      
        );
case 3:
  return (
    <div>
      <div className="mb-3">
        <label htmlFor="proof" >Add Proofs Details</label>
        <input
          id="proof"
          type="text"
          className="form-control"
          value={proof}
          onChange={(event) => setProof(event.target.value)}
          placeholder="Enter proof details..."
          required
        />
      </div>
      <button type="button" className="btn btn-secondary me-2" onClick={() => {setStep(step - 1);
            setResult(null);}}>Previous</button>
      <button type="submit" className="btn btn-primary" disabled={isLoading}>Submit</button>
    </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-container">
      <h1 className="form-step">Enter following details</h1>
      <form onSubmit={handleSubmit}>
        {renderStep()}
      </form>

      {/* Display Result */}
      {result && (
        <div className="form-step">
          {result.success ? (
            <div className="alert alert-success" role="alert">
              This message appears safe!
            </div>
          ) : (
            <div className="alert alert-danger" role="alert">
              {result.message}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DetectScam;
