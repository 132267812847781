import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './Home.css'; // Import CSS for styling

import VerifyBychannel from './Verify';

function LandingPage() {
  const navigate = useNavigate();


  const images = [
    "1.jpeg",
    "2.jpeg",
    "3.jpeg",
    "4.jpeg",
    "5.jpeg",
    "6.jpeg",
    "7.jpeg"
  ];

  const [currentImage, setCurrentImage] = useState(0);

  const handleDotClick = (index) => {
    setCurrentImage(index);
  };

  const handleButtonClick = () => {
    navigate('/verify');
  };

  return (
    <div className="landing-page">
      <div className="first-section">
        <div className="left-half">
        <h3 className="main-heading">Just Verify on <span className="VerifyPe">VerifyPe</span></h3>

        <p className="sub-heading">VerifyPe protects you from Frauds and Scams.</p>
        <p className="sub-heading">Verify unknown calls, messages, emails, job offers and more within few clicks!</p>
        <p className="sub-heading">Say goodbye to Fraud, Scam and Uncertainty!</p>
        
        <button className="home-button" onClick={handleButtonClick}>Verify Now!</button>
        </div>
        <div className="right-half">
        {/* <h3 className="main-heading2">Empowering Trust, Eliminating Fraud</h3> */}
          <img src="fraudcloud.jpeg" alt="VerifyPe" className="landing-image" />
        </div>
        {/* <div className="slider-container">
      <img src={images[currentImage]} alt={`Image ${currentImage + 1}`} className="landing-image" />
      <div className="dots-container">
        {images.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentImage ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          />
        ))}
      </div>
    </div> */}
      </div>
      <div className="second-section">
   
        <VerifyBychannel/>

      </div>

      <div className="third-section">
        <div className="mission">
          <h2 className="mission-heading">Our Mission</h2>
          <p className="sub-heading">We're on a mission to make the online world safer for everyone.</p>
          <p className="sub-heading">At VerifyPe, everyone is invited to verify information with ease, free from any doubts,hesitations or judgements.</p>
          <p className="sub-heading">Join us in our quest to put an end to scams and fraud targeting innocent individuals.</p>

        </div>
      </div>
      <footer className="second-section">
      <div className="container">
        <div className="row">
          <div className="col">
            <h5>Get in Touch</h5>
            <p>founder@verifype.com</p>
          </div>
          <div className="col  text-md-end">
            <p>© 2024 VerifyPe. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>

    </div>
  );
}

export default LandingPage;
