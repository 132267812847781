
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useParams } from 'react-router-dom';

function CaseList() {
  const [cases, setCases] = useState([]);

  useEffect(() => {
    fetchCases();
  }, []);

  const fetchCases = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/get-case-list`);

      const data = await response.json();
      if (data.success) {
        setCases(data.caselist);
      } else {
        console.error('Error fetching cases:', data.message);
      }
    } catch (error) {
      console.error('Error fetching cases:', error);
    }
  };

  return (
    <div>
      <h1>Case List</h1>
      <table>
        <thead>
          <tr>
            <th>Case Number</th>
            <th>Creation Date</th>
            <th>Case Status</th>
            <th>Verdict Status</th>
            <th>Verdict Date</th>
          </tr>
        </thead>
        <tbody>
          {cases.map((caseItem, index) => (
            <tr key={index}>
              <td>
                <Link to={`/admin-case-verdict?requestid=${caseItem.casenumber}`}>
                  {caseItem.casenumber}
                </Link>
              </td>
              <td>{caseItem.created_date}</td>
              <td>{caseItem.casestatus}</td>
              <td>{caseItem.verifiedcasestatus}</td>
              <td>{caseItem.verdictdate}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default CaseList;