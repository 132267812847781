import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const AdminCaseReview = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const requestIdFromURL = urlParams.get('requestid');
  const [statusData, setStatusData] = useState(null);
  const [searchRequestId, setSearchRequestId] = useState('');
  const [verfiiedCaseStatus, setVerifiedCaseStatus] = useState('');
  const [casedetails, setCasedetails] = useState(null);
  const [supportingLinks, setSupportingLinks] = useState(['']); // Initialize with an array containing one empty string

  const [expanded, setExpanded] = useState(false);


  const [fraudStatus, setFraudStatus] = useState('');
  const [description, setDescription] = useState('');
  const [modusOperandi, setModusOperandi] = useState('');
  const [verdict, setVerdict] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here
    console.log('Form submitted!');
  };

  const handleAddLink = () => {
    setSupportingLinks([...supportingLinks, '']); // Add an empty string to the array
  };

  const handleLinkChange = (index, value) => {
    const updatedLinks = [...supportingLinks];
    updatedLinks[index] = value;
    setSupportingLinks(updatedLinks);
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    if (requestIdFromURL) {
      fetchStatus(requestIdFromURL);
    }
  }, [requestIdFromURL]);

 
  const getColor = (value) => {
    if (value < 20) return ['green', 'No Fraud'];
    if (value < 40) return ['yellowgreen', 'Less Likely Fraud'];
    if (value < 60) return ['yellow', 'Suspicious'];
    if (value < 80) return ['orange', 'Highly Fraud'];
    return ['red', 'Confirmed Fraud'];
  };
  const [color, label] = getColor(verdict);

  const fetchStatus = async (requestId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_VERIFYPE_BACKEND}/get-verified-details?requestid=${requestId}`);
      const data = await response.json();
      console.log("verified_details : ",data.verified_details);

      console.log("casedetails :",data.verified_details.casedetails);


      
      const jsonString = data.verified_details.casedetails;

        // Replace single quotes with double quotes
        const validJsonString = jsonString.replace(/'/g, '"');

        try {
        // Convert JSON string to JSON object
        const jsonObject = JSON.parse(validJsonString);
        setCasedetails(jsonObject)
        console.log('parsing : ' , casedetails); // Output: { channel: 'whatsAPP' }
        } catch (error) {
        console.error('Error parsing JSON:', error);
        }

   
        if (!data.verified_details.verifiedcasestatus){
        setVerifiedCaseStatus('Pending');
      }
      setStatusData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleSearch = async () => {
    if (!searchRequestId) return;
    fetchStatus(searchRequestId);
  };

  return (
    <div>
          <h2>Status Update</h2>
       <div>
        <input
          type="text"
          placeholder="Enter Request ID"
          //value="SN9TH47WUO3UMYKS"
          value={searchRequestId}
          onChange={(e) => setSearchRequestId(e.target.value)}
        />
        <button onClick={handleSearch}>Search</button>
      </div>

        <br></br>

      {statusData ? (
        <>
        <div className='summary-container'>
            
        <div className={`first-half-summary ${expanded ? 'expanded' : ''}`}>
        
        <strong><label> Verification Status for {casedetails.channel.heading}</label></strong>

            <p className='disclaimer_class'>Verfication Request #: {statusData.verified_details.casenumber}</p>
            <p className='disclaimer_class'>Created on : {statusData.verified_details.created_date}</p>
            
            <br></br>
            {casedetails.channelcontacts.whatsappNumberVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.whatsappNumberVerify.toString()}</p>
            )}
            {casedetails.channelcontacts.smsNumberVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.smsNumberVerify.toString()}</p>
            )}

            {casedetails.channelcontacts.emailVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.emailVerify.toString()}</p>
            )}

            {casedetails.channelcontacts.callVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.callVerify.toString()}</p>
            )}


            {casedetails.channelcontacts.weblinkVerify && (
                <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.weblinkVerify.toString()}</p>
            )}


            {casedetails.channelcontacts.weblinkVerify && (
            <p className='label-summary'><strong>Verify: </strong>{casedetails.channelcontacts.weblinkVerify.toString()}</p>
            )}

            {casedetails.channelcontacts.purposeToVerify && (
                <p className='label-summary'><strong>Verfication Purpose: </strong>{casedetails.channelcontacts.purposeToVerify.toString()}</p>

            )}

            <p className='label-summary'><strong>Your Case: </strong><br></br>{casedetails.suspectedconversation.describedmessage.toString()}</p>
            <p className='label-summary'><strong>Suspect's Message: </strong><br></br>{casedetails.suspectedconversation.originalmessage.toString()}</p>
        
            
            <p className='label-summary'><strong>Suspect's Details: </strong></p>    
            <p className='label-summary'>Web-link: {casedetails.suspectcontactdetails.suspect_weblink.toString()}</p>
            <p className='label-summary'>Phone number: {casedetails.suspectcontactdetails.suspect_phone.toString()}</p>
            <p className='label-summary'>Email id: {casedetails.suspectcontactdetails.suspect_email.toString()}</p>
            <p className='label-summary'>Social Media: {casedetails.suspectcontactdetails.suspect_social.social_name.toString()} {casedetails.suspectcontactdetails.suspect_social.social_handle.toString()}</p>
    
            <p className='label-summary'><strong>Supporting Proofs: </strong></p>
                {casedetails.attachments.map((attachment, index) => (
                    <p key={index} className='label-summary'>
                    Description: {attachment.description.toString()} 
                    <img src={attachment.file} width="30px" alt="Attachment" />
                    </p>
                ))}
            </div>
            <a href="#" className="more-link" onClick={toggleExpanded}>
        {expanded ? 'Less' : 'More'}
        </a>

            <div className='second-half-summary'>
            <strong><label>Verfication Verdict</label></strong>
            <p className='disclaimer_class' >Verification Status: {verfiiedCaseStatus}</p>

            <form onSubmit={handleSubmit}>
      <label>Is this Fraud/Scam?
        <select style ={{width:'100%'}} value={fraudStatus} onChange={(e) => setFraudStatus(e.target.value)}>
          <option value="">Please Choose</option>
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </label>

      <label>Description:
        <textarea value={description} style ={{width:'100%'}} onChange={(e) => setDescription(e.target.value)} rows="4" cols="50"></textarea>
      </label>

      <label>Modus Operandi:
        <textarea value={modusOperandi} style ={{width:'100%'}}  onChange={(e) => setModusOperandi(e.target.value)} rows="4" cols="50"></textarea>
      </label>

      <label>Supporting Links:
        {supportingLinks.map((link, index) => (
          <div key={index}>
            <input type="text" style ={{width:'100%'}} value={link} onChange={(e) => handleLinkChange(index, e.target.value)} />
          </div>
        ))}
        <button type="button" onClick={handleAddLink}>+ Add</button>
      </label>

      <div className="verdict-container">
       <label>Verdict:
                <input type="range" 
                 className={`verdict-slider ${color}`}
                 value={verdict} onChange={(e) => setVerdict(e.target.value)} min="0" max="100" />
          <div className="slider-value" style={{ left: `${verdict}%`, color: color ,backgroundColor:'#D3D3D3'}}>
                             {verdict} - {label}          </div>
            </label>
            <div className="verdict-labels">
                <span>No Fraud</span>
                <span>Confirmed Fraud</span>
            </div>
      </div>

      <input type="submit" value="Submit" />
    </form>



            </div>
           
            </div>
        </>

      ) : (
        <p>No status available</p>
      )}
   
    </div>
  );
};

export default AdminCaseReview;
